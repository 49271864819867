/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Composables
import { createVuetify } from 'vuetify';

// components
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

// theme
import colors from 'vuetify/util/colors';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  directives,
  components,

  theme: {
    defaultTheme: 'dark',
    themes: {
      light: {
        colors: {
          primary: '#396fb9', // '#1867C0',
          secondary: '#5CBBF6',
        },
      },
      dark: {
        dark: true,
        colors: {
          primary: colors.blue.base,
          secondary: colors.pink.accent1,
          background: '#212121',
          surface: '#2c2c2c',
          error: colors.red.base,
        },
      },
    },
  },

  defaults: {
    VDataTableServer: {
      color: 'primary',
      fixedHeader: true,
      fixedFooter: true,
    },
    VTextField: {
      color: 'primary',
      density: 'compact',
      hideDetails: 'auto',
      variant: 'outlined',
    },
    VTextarea: {
      color: 'primary',
      density: 'compact',
      hideDetails: 'auto',
      variant: 'outlined',
    },
    VCheckbox: {
      color: 'primary',
      density: 'compact',
      hideDetails: 'auto',
    },
  },
});
