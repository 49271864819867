import { defineStore } from 'pinia';
import { ref } from 'vue';
import { SignInData, SignUpData, User } from '../types/auth';
import { useApi } from '../composables/api';
import cookies from 'js-cookie';

const TOKEN_KEY = 'tools.session-token';

export const useAuthStore = defineStore('auth', () => {
  const isAuthenticated = ref<boolean>(false);
  const token = ref<string | null>(null);
  const user = ref<User | null>(null);

  const signup = async (data: SignUpData) => {
    const res = await useApi().post('/auth/signup', data);
    setToken(res.data.token);
    user.value = res.data.user;
    isAuthenticated.value = true;
  };

  const signin = async (data: SignInData) => {
    const res = await useApi().post('/auth/signin', data);
    setToken(res.data.token);
    user.value = res.data.user;
    isAuthenticated.value = true;
  };

  const signout = () => {
    isAuthenticated.value = false;
    user.value = null;
    setToken(null);
  };

  const getCurrentUser = async (): Promise<User | null> => {
    try {
      const res = await useApi().get<User>('/auth');
      setToken(token.value);
      return res.data;
    } catch (err) {
      return null;
    }
  };

  const getIsAuthenticated = async () => {
    user.value = await getCurrentUser();
    if (user.value) {
      isAuthenticated.value = true;
    } else {
      isAuthenticated.value = false;
      setToken(null);
    }
    return isAuthenticated.value;
  };

  const getToken = () => {
    token.value = cookies.get(TOKEN_KEY) ?? null;
    return token.value;
  };
  const setToken = (value: string | null) => {
    token.value = value;
    if (value === null) {
      cookies.remove(TOKEN_KEY);
    } else {
      cookies.set(TOKEN_KEY, value, {
        expires: import.meta.env.AUTH_TOKEN_MAX_AGE / 24 / 60 / 60,
        secure: true,
        sameSite: 'strict',
      });
    }
  };

  return {
    signup,
    signin,
    signout,
    getCurrentUser,
    getIsAuthenticated,
    getToken,
    user,
    isAuthenticated,
  };
});
