import { useAuthStore } from '../store/auth';
import { SignInData, SignUpData } from '../types/auth';

export const useAuth = () => {
  const store = useAuthStore();

  const signin = async (data: SignInData) => {
    return await store.signin(data);
  };

  const signup = async (data: SignUpData) => {
    return await store.signup(data);
  };

  const signout = () => {
    return store.signout();
  };

  const isAuthenticated = async () => {
    store.getToken();
    return await store.getIsAuthenticated();
  };

  const getToken = () => {
    return store.getToken();
  };

  const getCurrentUser = async () => {
    store.getToken();
    return await store.getCurrentUser();
  };

  return {
    signin,
    signup,
    signout,
    getCurrentUser,
    isAuthenticated,
    getToken,
  };
};
