export enum Routes {
  Index = 'index',
  AuthSignin = 'signin',
  AuthSignup = 'signup',
  AdminDashboard = 'dashboard',
  Contacts = 'contacts',
  Invoices = 'invoices',
  Parts = 'parts',
  Categories = 'categories',
  Unities = 'unities',
  PartsImport = 'parts-import',
}
