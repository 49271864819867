// Composables
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { useAuth } from '../composables/auth';
import { useAppStore } from '../store/app';
import { Routes } from './routes';

const routes: RouteRecordRaw[] = [
  { path: '/', name: Routes.Index, redirect: { name: Routes.AdminDashboard } },
  {
    path: '/auth',
    component: () => import('@/layouts/auth.vue'),
    children: [
      {
        path: 'signin',
        name: Routes.AuthSignin,
        component: () => import('@/views/auth/signin.vue'),
        meta: { redirectIfAuthorized: true },
      },
      {
        path: 'signup',
        name: Routes.AuthSignup,
        component: () => import('@/views/auth/signup.vue'),
        meta: { redirectIfAuthorized: true },
      },
    ],
  },
  {
    path: '/admin',
    component: () => import('@/layouts/default.vue'),
    children: [
      {
        path: 'dashboard',
        name: Routes.AdminDashboard,
        component: () => import('@/views/admin/dashboard.vue'),
        meta: { title: 'Dashboard' },
      },
      {
        path: 'contacts',
        name: Routes.Contacts,
        component: () => import('@/views/admin/contacts.vue'),
        meta: { title: 'Kontakte' },
      },
      {
        path: 'invoices',
        name: Routes.Invoices,
        component: () => import('@/views/admin/invoices.vue'),
        meta: { title: 'Rechnungen' },
      },
      {
        path: 'parts',
        name: Routes.Parts,
        component: () => import('@/views/admin/parts.vue'),
        meta: { title: 'Produkte' },
      },
      {
        path: 'categories',
        name: Routes.Categories,
        component: () => import('@/views/admin/categories.vue'),
        meta: { title: 'Kategorien' },
      },
      {
        path: 'unities',
        name: Routes.Unities,
        component: () => import('@/views/admin/unities.vue'),
        meta: { title: 'Einheiten' },
      },
      {
        path: 'parts-import',
        name: Routes.PartsImport,
        component: () => import('@/views/admin/parts-import.vue'),
        meta: { title: 'Produkte importieren' },
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: '/(.*)*',
    redirect: { name: Routes.AdminDashboard, replace: true, force: true },
  },
  //FIXME remove later
  { path: '/invoices', redirect: { name: Routes.Invoices } },
  { path: '/parts', redirect: { name: Routes.Parts } },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  useAppStore().title = to.meta.title ?? 'TBW Tools';

  const authenticated = await useAuth().isAuthenticated();

  if (to.meta.requiresAuth && !authenticated) {
    return next({ name: Routes.AuthSignin, query: { redirect: to.fullPath } });
  }

  if (to.meta.redirectIfAuthorized && authenticated) {
    return next({ name: Routes.AdminDashboard });
  }

  return next();
});

export default router;
