// Utilities
import { defineStore } from 'pinia';
import { ref } from 'vue';
import pjson from '@/../package.json';

export const useAppStore = defineStore('app', () => {
  const version = `v${pjson.version}`;

  const drawerEnabled = ref<boolean>(true);
  const drawerOpen = ref<boolean>(true);
  const title = ref<string>('TBW Tools');


  const toggleDrawer = () => {
    drawerOpen.value = !drawerOpen.value;
  };

  return {version, drawerEnabled, drawerOpen, title, toggleDrawer };
});
